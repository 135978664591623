import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Button, Container } from 'react-bootstrap';
import Picture from '../components/common/Picture';
import breakpoints from '../components/common/breakpoints';
import { createRoot } from 'react-dom/client';
import NavbarExtraQueo from './NavbarExtra';
import { Link } from 'react-router-dom';

const NavbarQueo = () => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="queo-navbars">
            <NavbarExtraQueo />
            <Navbar variant="dark" expand="lg" className="queo-navbar"
                expanded={expanded}>
                <Container>
                    <Navbar.Brand href="/">
                        <Picture
                            src="https://queo-prod-s3.s3.us-west-2.amazonaws.com/media/landings/images/access+white.svg"
                            alt="Queo Access logo"
                            width="95px"
                            height="43px"
                            className="queo-img-95"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle onClick={() => setExpanded(expanded ? false : 'expanded')} aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end queo-navbar-links">
                        <Nav className="justify-content-end align-items-center">
                            <Nav.Item>
                                <NavDropdown title="SOLUCIONES" id="solutions-dropdown" className="queo-navbar-link">
                                    <Nav.Item>
                                        <h6 className="queo-dropdown-internal-title">Queo Access</h6>
                                    </Nav.Item>
                                    
                                    <Link to='/control-de-acceso' className='dropdown-item'>Control de acceso</Link>
                                    <NavDropdown.Divider />
                                    <Nav.Item>
                                        <h6 className="queo-dropdown-internal-title">Queo Parking</h6>
                                    </Nav.Item>
                                    <Link to='/optimizacion-de-parqueaderos' className='dropdown-item'>Optimización de parqueaderos</Link>
                                    <NavDropdown.Divider />
                                    <Nav.Item>
                                        <h6 className="queo-dropdown-internal-title">Queo spaces</h6>
                                    </Nav.Item>
                                    <Link className='dropdown-item' to="/puestos-colaborativos">Puestos colaborativos</Link>
                                    <Link className='dropdown-item' to="/salas">Salas de conferencias</Link>
                                    <Link className='dropdown-item' to="/lockers-inteligentes">Lockers</Link>
                                </NavDropdown>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link onClick={() => setExpanded(false)}
                                    href="/#success-stories"><span className="queo-navbar-link">CLIENTES</span></Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <span className="nav-item-lines right">
                                    <Nav.Link onClick={() => setExpanded(false)}
                                        href="/#descargar"><span className="queo-navbar-link">DESCARGAR APP</span></Nav.Link>
                                </span>
                            </Nav.Item>

                             <Nav.Item>
                                <Button
                                    onClick={() => setExpanded(false)}
                                    href="/#soporte" className="button rounded-pill w-100 queo-navbar-highlighted-btn orange" size="sm">
                                    VENTAS
                                </Button>
                            </Nav.Item>
                            
                           <Nav.Item>
                                <a className="queo-navbar-contact-phone" href="https://wa.link/9edqve" rel="noreferrer" target="_blank">+57 313 888 2923</a>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <style jsx>{`
                .queo-navbars {
                    position: fixed;
                    width: 100%;
                    z-index: 3;
                }

                @media ${breakpoints.lg} {
                    .nav-item-lines {
                        position: relative;
                        display: inline-block;
                        margin: auto 7px;
                        padding: 0 3px;
                    }

                    .nav-item-lines:before, .nav-item-lines:after {
                        position: absolute;
                        top: calc(50% - 6px);
                        content: '';
                        width: 1px;
                        height: 12px;
                        background: #FFF;
                    }

                    .nav-item-lines:before{
                        left: 0;
                    }

                    .nav-item-lines:after{
                        right: 0;
                    }

                    .nav-item-lines.right {
                        margin-left: 0;
                    }

                    .nav-item-lines.right:before {
                        display: none;
                    }
                }
            `}</style>
        </div>
    );
};
if (typeof window !== 'undefined')
    if (document.getElementById('navbar')) {
        const container = document.getElementById('navbar');
        const root = createRoot(container);
        root.render(<NavbarQueo />);
    }

export default NavbarQueo;
